import React from 'react'
import {Container} from "@material-ui/core";
import SEO from "../../components/seo/seo";
import Text from "../../components/data-display/text";
import makeStyles from "@material-ui/core/styles/makeStyles";
import InlineLink from "../../components/data-display/inline-link";

const useStyles = makeStyles(() => ({
    subHeading: {
        marginBottom: "12px"
    },
}));

const PrivacyPolicy = () => {

    const classes = useStyles();

    return (
        <>
            <SEO description={"Sydetasker will solely share and disclose your data in accordance with your instructions, including any applicable terms in the Terms of Service, and in compliance with applicable law and legal process."} title={"Privacy Policy"}/>
            <div style={{ width: "100%", background: "rgb(235,235,235)", height: "30vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} >
                <div style={{ height: "64px" }}/>
                <Text variant={"h1"} black style={{ textAlign: "center" }}>{"Privacy Policy"}</Text>
            </div>
            <Container fixed maxWidth={"md"}>
                <div style={{ padding: "10vh 16px" }}>
                    <Text variant={"body1"} medium className={classes.subHeading}>
                        Effective: 18th June 2020
                    </Text>
                    <div style={{ height: "24px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                        What is a Privacy Policy?
                    </Text>
                    <Text variant={"body1"} regular>
                        This policy explains what information is collected, how our company collects, uses, stores, discloses and disseminates any user information, and how you can access data we’ve collected on your use.
                        <br/>
                        <br/>
                        Sydetasker values customer privacy and will only disclose collected or stored information as required by law.
                        <br/>
                        <br/>
                        Sydetasker’s Privacy Policy applies to Sydetasker’s website and services and not to any other website not controlled by Sydetasker. A link to a third-party website does not mean that we endorse it or the quality or accuracy of information on it. If you decide to visit a third-party website, you are subject to that website’s privacy policy and terms and conditions.
                    </Text>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                        What information do we collect?
                    </Text>
                    <Text variant={"body1"} regular>
                        Sydetasker only collects information necessary to render services through the site and voluntarily supplied. This includes personal information such as names, addresses, email addresses, skills listed and username.
                        <br/>
                        <br/>
                        We also may collect some meta and usage data on our website including:
                        <ul>
                            <li>Time spent on certain pages</li>
                            <li>Searches</li>
                            <li>Browser type</li>
                            <li>Device type</li>
                            <li>The operating system being used</li>
                            <li>Other webpages being visited during your session</li>
                        </ul>
                        All credit card payments are processed by Stripe. All information processed through Stripe is subject to Stripe’s Terms and Conditions and Privacy Policy. Sydetasker does not collect, store, or have access to any credit card information.
                        <br/>
                        <br/>
                        If you choose to save your credit card information on the site, that information will be stored on Stripe’s server, not Sydetasker’s.
                    </Text>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                        How do we collect information?
                    </Text>
                    <Text variant={"body1"} regular>
                        Sydetasker collects some information manually, including some personal information. Sydetasker also uses Stripe to handle all billing information and Google Analytics to collect meta data and usage data.
                        <br/>
                        <br/>
                        Stripe is a billing platform that Sydetasker uses. Stripe will collect credit card information and billing name and address to process payments.
                        <br/>
                        <br/>
                        Google Analytics is a tool commonly used by businesses to understand how customers are interacting with their websites. This tool will be used to collect website usage data.
                        Google Analytics uses cookies to collect information.
                    </Text>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                        What are cookies?
                    </Text>
                    <Text variant={"body1"} regular>
                        “Cookies” are small computer files sent to or accessed from your web browser or your computer’s or tablet’s hard drive that contain information about your computer, such as a user ID, user settings, browsing history, and activities conducted while using the Services. Cookies themselves are not personally identifiable, but may be linked to Personal Information provided to us through interaction with the website. A cookie typically contains the name of the domain (internet location) in which the cookie originated, the “lifetime” of the cookie, and a randomly generated unique number or similar identifier.
                        <br/>
                        <br/>
                        For more information about how Google Analytics uses cookies to collect information, visit the following site.
                        <br/>
                        <InlineLink to={"https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"}>https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage</InlineLink>
                    </Text>
                    <div style={{ height: "48px" }}/>
                    <Text variant={"h6"} bold className={classes.subHeading}>
                       Your Rights and Choices
                    </Text>
                    <Text variant={"body1"} regular>
                    Our users have the right to access, edit, update, restrict processing and/ or delete the personal data that we hold about you or to receive a copy of it in a portable format by contacting us at contact@sydetaker.com. Where we process your personal data with your consent, you may withdraw it at any time. You also have a right to object to processing based on legitimate interests. These rights may be limited in some circumstances, for example, if:   
                        <br/>
                        <br/>
                        <ul>
                            <li>We are legally permitted or required to deny you access to, and/ or to retain, the information because we are subject to a legal requirement or have a compelling legitimate interest; or</li>
                            <li>You make a request that is unreasonably repetitive, requires Sydetasker to make a disproportionate effort, risks the privacy of others, or there are other valid reasons why we cannot comply.</li>
                        </ul>
                        If you have concerns about how we handle your personal data or require further information, please email Sydetasker at contact@sydetasker.com. If you have unresolved complaints, you have the right to complain to a data protection authority.
                    </Text>
                    <div style={{ height: "48px" }}/>
                    {/*<div style={{ height: "48px" }}/>*/}
                    {/*<Text variant={"h6"} bold className={classes.subHeading}>*/}
                    {/*    Why do we collect this information and how do we use it?*/}
                    {/*</Text>*/}
                    {/*<Text variant={"body1"} regular>*/}
                    {/*    Your personal information is collected primarily to conduct a background check to ensure our Posters have safe Taskers providing services. Other information is used for a variety of reasons:*/}
                    {/*</Text>*/}
                </div>
            </Container>
            <div style={{ height: "1px", width: "100%", background: "rgb(220,220,220)" }}/>
        </>
    )
}

export default PrivacyPolicy